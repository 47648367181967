
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import Multiselect from '@vueform/multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'course-summary-report',
  components: {
    axios,
    Datatable,
    Multiselect,
  },
  data() {
    return {
      api_url: '',
      entity_id: [] as any,
      tranche_id: [] as any,
      association: [] as any,
      TotalInstitute: 0 as any,
      Totalinstitutecoursetarget: 0 as any,
      Totalenrollment: 0 as any,
      Totalenrollment_female: 0 as any,
      Totalenrollment_male: 0 as any,
      Totalcertification: 0 as any,
      Totalcertification_female: 0 as any,
      Totalcertification_male: 0 as any,
      Totaljobplacement: 0 as any,
      Totaljobplacement_female: 0 as any,
      Totalassesment: 0 as any,
      Totalassesment_female: 0 as any,

      data: [],
      tranches_info: [] as any,
      optionsTPartner: [] as any,
      optionsTranche: [] as any,
      courseSummaryReport: [] as any,
      componentKey: 0,
      loading: false,
      load: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      TotalCourse: 0 as any,
      TotalBatch: 0 as any,
      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranches();
    await this.getAssociation();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('entity_id', this.entity_id);
      let data = `${this.VUE_APP_API_URL}/api/report/institute-enrollment-summary_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',

      });
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('entity_id', this.entity_id);
      await ApiService.post('report/institute-enrollment-summary_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('entity_id', this.entity_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/institute-enrollment-summary_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'institute-enrollment-summary.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    filterEntity(entity) {
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes('select')) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.association.shift();

        this.association.forEach((val, index) => {
          if (val.id != 'select') {
            this.entity_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.association = [];
        this.association.push(this.UnselectObj);
      }
      if (entity.includes('unselect')) {
        this.associationData = [];
        //unshift koro select obj
        this.association = [];

        this.association = this.tempAssociation;
        this.association.unshift(this.selectObj);
        this.entity_id = '';
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {

          this.tranches_info = response.data.data;
          this.tranches_info.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getAssociation() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;

          this.association.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    trainingProviderNotice() {
      if (this.tranche_id != '' && this.entity_id != '') {
        this.showCourseNotice = true;
        this.loading = true;
        this.load = true;
        ApiService.get(
          'report/institute-enrollment-summary?tranche_id=' +
          this.tranche_id +
          '&entity_id=' +
          this.entity_id
        )
          .then((response) => {
            this.loading = false;
            this.courseSummaryReport = response.data.data.coursedata;
            this.TotalInstitute = response.data.data.TotalInstitute;
            this.TotalCourse = response.data.data.TotalCourse;
            this.TotalBatch = response.data.data.TotalBatch;
            this.Totalenrollment = response.data.data.Totalenrollment;
            this.Totalinstitutecoursetarget =
              response.data.data.Totalinstitutecoursetarget;
            this.Totalenrollment_female =
              response.data.data.Totalenrollment_female;

            this.Totalcertification = response.data.data.Totalcertification;
            this.Totalenrollment_male = response.data.data.Totalenrollment_male;
            this.Totalcertification_female =
              response.data.data.Totalcertification_female;
            this.Totalcertification_male =
              response.data.data.Totalcertification_male;
               this.Totaljobplacement = response.data.data.Totaljobplacement;
            this.Totaljobplacement_female = response.data.data.Totaljobplacement_female;
                  this.Totalassesment = response.data.data.Totalassesment;
            this.Totalassesment_female = response.data.data.Totalassesment_female;

            this.load = false;
            this.loading = false;
            console.log(response);
          })
          .catch((response) => {
            this.loading = false;
            this.load = false;

            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          text: 'Please Select Tranche and Training Partner!!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        this.load = false;
        this.loading = false;
      }
    },
  },
  setup() { },
});
